<script>
export default {
  name: 'CpSidebarMenuGlobal',
  components: {
    CpBaseSvgIcon: require('./base-svg-icon').default,
    SidebarMenuHomeIcon: require('./social-feed/icons/home').default,
    SidebarMenuTvIcon: require('./social-feed/icons/tv').default,
    SidebarMenuPetitionIcon: require('./social-feed/icons/petition').default,
    SidebarMenuDebatesIcon: require('./social-feed/icons/debates').default,
    SidebarMenuRacesIcon: require('./social-feed/icons/races').default,
    CpInviteMembersDialog: require('./dialogs/invite-members').default,
  },
  data: function () {
    return {
      isOpenMoreOptions: false,
    };
  },
  methods: {
    handleMoreOptions() {
      this.isOpenMoreOptions = !this.isOpenMoreOptions;
    },
  },
};
</script>
<template>
  <div class="sidebar-menu-global">
    <div class="sticky-sidebar">
      <nav v-bind:class="{ mobile: $root.isMobile, tablet: $root.isTablet }">
        <a class="sidebar-link" v-bind:class="{ mobile: $root.isMobile }" href="/feed">
          <div class="social-sidebar-menu-item">
            <i class="fal fa-compass icon"></i>
            <span class="buttons sidebar-menu-title">Latest</span>
          </div>
        </a>
        <a class="sidebar-link" href="/feed#campaigns" v-bind:class="{ mobile: $root.isMobile }">
          <div class="social-sidebar-menu-item">
            <i class="fal fa-podium icon"></i>
            <span class="buttons sidebar-menu-title">Campaigns</span>
          </div>
        </a>
        <a class="sidebar-link" href="/feed#community" v-bind:class="{ mobile: $root.isMobile }">
          <div class="social-sidebar-menu-item">
            <i class="fal fa-people-arrows icon"></i>
            <span class="buttons sidebar-menu-title">Community</span>
          </div>
        </a>
        <a class="sidebar-link" href="/feed#news" v-bind:class="{ mobile: $root.isMobile }">
          <div class="social-sidebar-menu-item">
            <i class="fal fa-rss-square icon"></i>
            <span class="buttons sidebar-menu-title">News</span>
          </div>
        </a>
        <a class="sidebar-link" href="/feed#groups" v-bind:class="{ mobile: $root.isMobile }">
          <div class="social-sidebar-menu-item">
            <i class="fal fa-user-friends icon"></i>
            <span class="buttons sidebar-menu-title">Groups</span>
          </div>
        </a>
        <a class="sidebar-link" href="/feed#polls" v-bind:class="{ mobile: $root.isMobile }">
          <div class="social-sidebar-menu-item">
            <i class="fal fa-poll icon"></i>
            <span class="buttons sidebar-menu-title">Polls</span>
          </div>
        </a>
        <a class="sidebar-link" href="/feed#lobbying" v-bind:class="{ mobile: $root.isMobile }">
          <div class="social-sidebar-menu-item">
            <i class="fal fa-landmark icon"></i>
            <span class="buttons sidebar-menu-title">Lobbying</span>
          </div>
        </a>
        <a class="sidebar-link" v-on:click="handleMoreOptions()" v-bind:class="{ mobile: $root.isMobile }">
          <div class="social-sidebar-menu-item">
            <i class="fal icon" :class="{ 'fa-plus-circle': !isOpenMoreOptions, 'fa-minus-circle': isOpenMoreOptions }"></i>
            <span class="buttons sidebar-menu-title">More...</span>
          </div>
        </a>
        <div v-show="isOpenMoreOptions" class="more-options-container">
          <a class="sidebar-link" href="/feed#members" v-bind:class="{ mobile: $root.isMobile }">
            <div class="social-sidebar-menu-item">
              <i class="fal fa-users icon"></i>
              <span class="buttons sidebar-menu-title">Members</span>
            </div>
          </a>
          <a class="sidebar-link" href="/feed#races" v-bind:class="{ mobile: $root.isMobile }">
            <div class="social-sidebar-menu-item">
              <i class="fal fa-flag-usa icon"></i>
              <span class="buttons sidebar-menu-title">Races</span>
            </div>
          </a>
          <a class="sidebar-link" href="/feed#events" v-bind:class="{ mobile: $root.isMobile }">
            <div class="social-sidebar-menu-item">
              <i class="fal fa-calendar-day icon"></i>
              <span class="buttons sidebar-menu-title">Events</span>
            </div>
          </a>
          <a class="sidebar-link" href="/feed#petitions" v-bind:class="{ mobile: $root.isMobile }">
            <div class="social-sidebar-menu-item">
              <i class="fal fa-file-signature icon"></i>
              <span class="buttons sidebar-menu-title">Petitions</span>
            </div>
          </a>
          <a class="sidebar-link" href="/feed#blogs" v-bind:class="{ mobile: $root.isMobile }">
            <div class="social-sidebar-menu-item">
              <i class="fal fa-edit icon"></i>
              <span class="buttons sidebar-menu-title">Blogs</span>
            </div>
          </a>
          <!-- ToDo: Remove code commented after update the section  -->
          <!-- <a class="sidebar-link" href="/feed#tv" v-bind:class="{ mobile: $root.isMobile }">
            <div class="social-sidebar-menu-item">
              <i class="fal fa-video icon"></i>
              <span class="buttons sidebar-menu-title">Crowdpac TV</span>
            </div>
          </a> -->
        </div>
      </nav>
    </div>
  </div>
</template>

<style scoped>
nav {
  background: white;
}

div.sidebar-menu-global {
  position: relative;
}

div.social-sidebar-menu-item {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.1rem;
  font-family: 'proxima-nova', Gotham, Helvetica, sans-serif;
  height: 100%;
  padding: 8px;
}

a.sidebar-link {
  display: block;
  color: #444;
  font-weight: 600;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  cursor: pointer;
  border-radius: 4px;
  margin: 10px 0;
  text-decoration: none;
}
a.sidebar-link.mobile .sidebar-menu-title {
  display: none;
}
a.sidebar-link:hover {
  color: var(--highlight);
  background-color: #ffe3da;
}
a.sidebar-link.active {
  color: var(--highlight);
  background-color: #ffe3da;
}
a.sidebar-link.active.mobile {
  background-color: #ffffff;
}
nav.tablet .social-sidebar-menu-item {
  padding: 5px 5px 5px 10px;
  margin-bottom: 7px;
}
nav.tablet span.sidebar-menu-title {
  font-size: 0.8em;
  padding-left: 8px;
}

nav.tablet .social-sidebar-menu-item svg {
  max-width: 25px;
}
.sticky-sidebar {
  position: sticky;
  top: 20px;
}

span.sidebar-menu-title {
  padding-left: 4px;
  cursor: inherit;
  flex: 5;
}
nav.mobile div.social-sidebar-menu-item {
  padding: 0px;
  margin-bottom: 5px;
  justify-content: center;
}
.icon {
  font-size: 22px;
  flex: 1;
}
.more-options-container {
  padding-left: 20px;
}
@media screen and (max-width: 768px) {
  .more-options-container .icon {
    font-size: 18px;
  }
}
@media screen and (max-width: 450px) {
  .more-options-container .icon {
    font-size: 16px;
  }
}
</style>
